const UpdateList = Symbol();
const updatePageData = Symbol();

const initialState = {
  billList:[],
  paging:{
    index:1,
    orderBy:"id",
    size:10,
    sort:"desc"
  }
}


export default (state = initialState, action) => {
  switch (action.type) {
    case UpdateList:
      return {
        ...state,
        billList: action.data
      }
    case updatePageData:
    let paging = state.paging
    paging[action.subType] =action.data
      return {
        ...state,
        ...paging
      }

    default:
      return state
  }
}

export const dispatch_OrderDetailBill_func = {

  updateListData: (data) => {
    return dispatch => {
      dispatch({
        type: UpdateList,
        data: data
      });
    }
  },
  updatePageData: (data,type) => {
    return dispatch => {
      dispatch({
        type: updatePageData,
        data: data,
        subType:type
      });
    }
  },

}

