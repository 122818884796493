
import axios from 'axios'
import React, { Component } from 'react';
import Loading2 from '../component/loading2'

//设置时间超时
let instance = axios.create()
instance.defaults.timeout = 5000000000000000000
axios.interceptors.request.use(
  config => {
    isLoading(config)
    return config
  },
  error => {
    Axios.Show.finish()
    return Promise.reject(error)
  }
)
//http response 封装后台返回拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code == -2100) {
      // window.location.href = "/errorPage?msg=对不起, 用户验证失败"
       window.location.href='/login?redirect='+window.location.pathname+ window.location.search
    }
  
    Axios.Show.finish()
    return response
  },
  error => { 
    // alert('系统异常')
    // window.location.href='/login?redirect='+window.location.pathname+ window.location.search
    Axios.Show.finish()
    //如果返回这个message 就直接跳到登录页面
    return Promise.reject(error)
  }
)

function Axios() {
  return axios
}

function isLoading(config) {
  switch (config.method) {
    case 'get':
      if (config.params && !(('loading' in config.params))){     
        Axios.Show.loading()
      }
      break;
    case 'post':
      if (typeof config.data === 'object') {
        if (!config.data.loading) {
          Axios.Show.loading()
        }
      }
      break;
    default:
      break;
  }
}

Axios.loading = class Loading extends Component {
  constructor(props){
    super(props)

    Axios.Show = this
    this.state = {
      isLoading : false
    }

  }
  finish = () => {
    this.setState({
      isLoading:false
    })
  }
  loading=()=>{
    this.setState({
      isLoading: true
    })
  }

  render(){
    return (
      <div>
        <Loading2
          isShow={this.state.isLoading}
        />
      </div>
    )
  }
}




const get = (url, params) => {
  return axios.get(url, { params })
}
const post = (url, params) => {
  return axios.post(url, params)
}

// const post2 = (url, params) => {
//   return axios.post(url, {},{params,responsType: 'blob'})
// }
const post2 = (url, data, config) => axios.post(url, data, config);


let requests = { ...axios }
requests.get = get
requests.post = post
requests.post2 = post2
requests.loading = Axios.loading
export const request = requests


export default requests