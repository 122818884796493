
let apiUrlobj = {
  getGatherList:'/api/finance/getGatherList',//获取结账列表

  getAtsConfigList:'/api/ats/config/list',
  getAtsConigByKeyAndPwd :'/api/ats/config/getFileByKeyAndPwd',//查询文件
  atsConfigSaveUrl :'/api/ats/config/save',//配置保存
  atsConfigDeleteUrl :'/api/ats/config/delete',//配置删除

  loginUrl:'/api/auth/login',//登录地址
  getUser:'/api/auth/getUser',//获取用户
  signOutUrl:'/api/auth/signOut',//退出登录


  headUrl:'http://www.ats-srl.org/root/project/static/',//静态资源
  uploadUrl:'/api/file/upload',//上传



}



export const apiUrl = apiUrlobj;

