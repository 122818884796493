import React from 'react';
import style from "./index.module.scss"
import { Spin, Icon } from 'antd';
export default function Loading(props) {
  if (props.isLoading) {
    if (props.timedOut) {
      return <div>网络延误，请重新刷新页面</div>;
    } else if (props.pastDelay) {
    
      return <div className={style.reqLoading}>
        <Spin />
      </div>
    } else {
      return null;
    }
  } else if (props.error) {
    return <div>网络延误，请重新刷新页面</div>;
  } else {
    return null;
  }
}