import Moment from 'moment'
import { notification } from "antd";
// import { uploadFile, headerUrl } from "@/services/commom";

const uploadFile ='http://localhost:86/api/chat/upload/'
const headerUrl='http://www.ats-srl.org/root/project/static/'

//判断对象是否为空
export const isNullValue = (input) => {
  return input === undefined || input === null || input === '' || (String)(input).trim() === '';
};


/* 时间戳转日期 */
export const formatDateTime = (timeStamp) => {
  if (timeStamp !== null && timeStamp !== '') {
    var date = new Date();
    date.setTime(timeStamp);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  } else {
    return '';
  }
}


//获取URL参数
export const getQueryParam = (key) => {
  let reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
  let search = window.location.search
  let result = search.substr(1).match(reg);
  return result ? decodeURIComponent(result[2]) : null;
};

//获取指定名称的cookie的值
export const getCookie = (objName) => {
  var arrStr = document.cookie.split("; ");
  for (var i = 0; i < arrStr.length; i++) {
    var temp = arrStr[i].split("=");
    if (temp[0] === objName) return unescape(temp[1]);
  }
  return "";
}

//设置cookie的值
export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + escape(cvalue) + "; " + expires;
}


//获取当前日期
export const getNowFormatDate = () => {
  let date = new Date();
  let seperator1 = "-";
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  return year + seperator1 + month + seperator1 + strDate;
};






//获取静态资源的部署路径
export const public_url = process.env.PUBLIC_URL;


//区分开发环境，用于开发环境环境设置值
export const returnVal = (devValue, value) => {
  if (process.env.NODE_ENV === 'development') {
    return devValue;
  }
  else {
    return value;
  }
};

//时间格式化
export const formatDate = (format, times) => {
  var newDate = new Date(times);
  var date = {
    "M+": newDate.getMonth() + 1,
    "d+": newDate.getDate(),
    "h+": newDate.getHours(),
    "m+": newDate.getMinutes(),
    "s+": newDate.getSeconds(),
    "q+": Math.floor((newDate.getMonth() + 3) / 3),
    "S+": newDate.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (newDate.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in date) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1
        ? date[k] : ("00" + date[k]).substr(("" + date[k]).length));
    }
  }
  return format;
}


//输入时间戳返回 (data)=> 1 今天内，data为相差小时  2 本月内，data为几天前 3 超过一个月，data为输入时间日期
export const transformTime = (time) => {
  // let oneDay = 24 * 60 * 60 * 1000
  let nowTime = new Date().getTime()

  //获取今天24点的时间戳
  // var todayYear = (new Date()).getFullYear();
  // var todayMonth = (new Date()).getMonth();
  // var todayDay = (new Date()).getDate();
  // var todayTime = (new Date(todayYear, todayMonth, todayDay)).getTime() + oneDay;//毫秒

  let differ = Math.floor((nowTime - time) / 1000 / 60 / 60) //相差多少小时
  let differDay = Math.floor(differ / 24)
  if (differ < 24) {
    // return {type:1,data:differ}
    return differ + '小时前'
  }
  else {
    if (differDay > 30) {
      // return {data:Moment(time).format('YYYY-MM-DD'),type:3}
      return Moment(time).format('YYYY-MM-DD')
    } else {
      // return {data:differDay,type:2}
      return differDay + '天前'
    }
  }
}

export const deepCopy = function deepCopy(obj, cache) {
  if (cache === void 0) cache = []
  if (obj === null || typeof obj !== 'object') {
    return obj
  }
  let hit = find(cache, function (c) {
   return  c.original === obj
  });
  if (hit) {
    return hit.copy
  }
  let copy = Array.isArray(obj) ? [] : {};
  cache.push({
    original: obj,
    copy: copy,
  })
  Object.keys(obj).forEach(function (key) {
    copy[key] = deepCopy(obj, cache)
  })
  return obj
}
const find = function(list, fn)  {
  return  list.filter(fn)[0]
}


//富文本上传图片
export const braftEditorMediaConfig = () => {
  const myUploadFn = (param) => {
    const serverURL = uploadFile;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    const successFn = (response) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      window.response = xhr.responseText;
      let { data } = JSON.parse(xhr.responseText);
      param.success({
        url: headerUrl + data.path,
        meta: {
          title: data.originalFileName,
          alt: data.originalFileName,
        }
      });
    };

    const progressFn = (event) => {
      // 上传进度发生变化时调用param.progress
      param.progress(event.loaded / event.total * 100);
    };

    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: "unable to upload."
      });
    };

    xhr.upload.addEventListener("progress", progressFn, false);
    xhr.addEventListener("load", successFn, false);
    xhr.addEventListener("error", errorFn, false);
    xhr.addEventListener("abort", errorFn, false);

    fd.append("file", param.file);
    xhr.open("POST", serverURL, true);
    xhr.send(fd);

  };

  const myValidateFn = (file) => {
    let typeArr = ["image/jpg", "image/png", "image/jpeg", "image/gif", "image/bmp"];
    if (!typeArr.includes(file.type)) {
      notification["warning"]({
        message: "请上传2M以内, JPG、JPEG、PNG、GIF、BMP格式图片",
      });
      return false;
    }
    if (file.size > 1024 * 1024 * 2) {
      notification["warning"]({
        message: "请上传2M以内, JPG、JPEG、PNG、GIF、BMP格式图片",
      });
      return false;
    }
    return true;
  };

  return ({
    uploadFn: myUploadFn,
    validateFn: myValidateFn,
    accepts: "image/png,image/jpeg,image/gif,image/jpeg,image/bmp",
    externals: []
  });
};


/**
 * 节流函数
 * @param {*} func 
 * @param {*} interval 
 */
export function throttle(func, interval = 100) {
  let timeout;
  let startTime = new Date();
  return function (event) {
      event.persist && event.persist()   //保留对事件的引用
      clearTimeout(timeout);
      let curTime = new Date();
      if (curTime - startTime <= interval) {
          //小于规定时间间隔时，用setTimeout在指定时间后再执行
          timeout = setTimeout(() => {
              func(event);
          }, interval)
      } else {
          //重新计时并执行函数
          startTime = curTime;
          func(event)
      }
  }
}

/**
 * 将img标签转换为【图片】
 * @param {string} str 
 */
export function replaceImg(str){
  if(typeof str === 'string'){
      str = str.replace(/<img(.*?)>/g, "[图片]")
  }
  return str
}


const LOGIN_COOKIE_NAME = 'sessionId'

export function isAuthenticated() {
  return _getCookie(LOGIN_COOKIE_NAME)
}

export function authenticateSuccess(token) {
  _setCookie(LOGIN_COOKIE_NAME, token)
}

export function logout() {
  _setCookie(LOGIN_COOKIE_NAME, '', 0)
}

function _getCookie(name) {
  let start, end
  if (document.cookie.length > 0) {
    start = document.cookie.indexOf(name + '=')
    if (start !== -1) {
      start = start + name.length + 1
      end = document.cookie.indexOf(';', start)
      if (end === -1) {
        end = document.cookie.length
      }
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}

function _setCookie(name, value, expire) {
  let date = new Date()
  date.setDate(date.getDate() + expire)
  document.cookie = name + '=' + escape(value) + '; path=/' +
    (expire ? ';expires=' + date.toGMTString() : '')
}




