import React from "react";
import style from './index.module.css'
import { Spin, Icon } from 'antd';
import cln from 'classnames'
const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />;
const Loading =  (props) =>{
  return (
    <div className={cln(style.showbox,props.isShow?'':style.hide)}>
      <div className="loader">
        <Spin  />
      </div>
    </div>
  )
}

export default Loading