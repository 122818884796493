/**
 * Created by songbin on 2018/6/27.
 */
import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Component ,{Link ,mapFn,mapStateProps}from '~L/component'
import {dispatch_common_func} from '~R/common'
import style from './index.module.scss'
import {public_url,getCookie} from '~T/common'
import {Modal,Tabs,Checkbox,Button ,message,Icon,Input,Avatar} from 'antd'
import {Dropdown,Menu} from 'antd'
import {NavLink} from "react-router-dom";
import { setCookie } from '../../tools/common';


require('./index.css');
// const baseUrl = 'http://10.37.7.135'
const TabPane = Tabs.TabPane;
message.config({
  maxCount: 1,
});


class LoginBox extends Component {
  constructor(props) {
    super(props)
  }



  render() {
    let props = this.props
    // let loginData = props.loginData
    // let useInfo = props.useInfo
    let menu = (
    
      <Menu>

        <Menu.Item onClick={props.handleLogOut}>
          <span className={style.tac} >退出登录</span>
        </Menu.Item>
      </Menu>
    )
    // let { desc} = useInfo
    return(
      <div className={style.loginBox}>
        {
          props.isLogin?
            <Dropdown  overlay={menu}>

               <div className={this.className(style.flex,style.didLogin)}>
                {/* <img src={public_url+"/static/image/head.png"} /> */}
                <Avatar  icon="user" />
                <span style={{marginLeft:"5px"}}  size="small">{props.loginData.userInfo.userName}</span>
               
              </div>
            </Dropdown>
            :
          <div className={this.className(style.flex,style.LoginName)}>
              <img src={public_url+"/static/image/head.png"} />
              <span>{''}</span>
          </div>
        }
      </div>
    )
  }
}




let state1 =  mapStateProps('common')
@connect(state1)
class Nav extends Component {
  constructor(props) {
    super(props)

  }
  backToHome=()=>{
    if(window.location.pathname === '/'){
      window.location.reload()
    }else{
      this.props.history.push('/')
    }
  }
  render() {
    let props = this.props
    let superMaster = props.loginData.userInfo.superMaster
    return (
      <ul className={this.className(style.flex,style.nav)}>
       <img className={style.navImage} src={public_url+"/static/image/meinv.jpg"} alt="" onClick={this.backToHome}/>
        <li>
         
           <span style={{cursor:"pointer"}} className={style.applicationName} onClick={()=>{window.location.href = "/originality-control-atp-adr-tyapp"}}>首页</span>
        </li>
        {props.data.map((Item,index)=>{
          return(
            
            // Item.name == '配置' && !superMaster ?
            // ''
            // :
            <li key={index}>
              <NavLink 
              className={window.location.pathname === "/" && Item.path === "/"?style.default:''}
              activeClassName={style.acitve} to={Item.path}>
              {Item.name}
              </NavLink>
            </li>
           
            
          )
        })}
      </ul>
    )
  }
}

let fn = mapFn(dispatch_common_func)
let state =  mapStateProps('common')
@connect(state,fn)
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //一级导航
      navData: [
  
        
        {path: '/ats', name: 'ats'},
     
        
            
      ],
      visible:false
    }
    
    //检验登录
    this.checkIsLogin()
  }
  componentDidMount(){
 
  }

  //检验是否登录
  checkIsLogin=()=>{
      this.$http.get(this.apiUrl.getUser)
        .then(res=> {
          // res = {"status":"0000","msg":"success","data":{"id":911241,"realName":"\u674e\u5c1a\u6e05","displayName":"13*******76","sex":2,"wxId":"og3uK0sF4DnqXUDsFH6tzG-SkFhY","is_auth":"true","token":"1.011aa33cc716183ba53d720eef78ef245","user":{"salt":null,"displayName":"13*******76","mobile":"13824495176","mtime":1527927090000,"ucAvatar":"","lastLoginIp":"61.144.21.136","realName":"\u674e\u5c1a\u6e05","lastLoginTime":1542021066000,"password":null,"ctime":1527927090000,"id":911241,"additialAttributes":{"birthday":"19900505","clientId":null,"idCard":"440883199005051163","sex":2,"wxId":"og3uK0sF4DnqXUDsFH6tzG-SkFhY","qqId":null,"certification":1,"wbId":null,"zfbId":null,"appId":0,"certificationTime":1528080391000,"credit":734,"email":null},"username":null,"desc":"13*******76","status":8,"token":"1.011aa33cc716183ba53d720eef78ef245"}},"request_id":1476580193}
          if (res.data.code === 1) {
            let result = res.data.data
            
            let loginData = {
              isLogin:true,
              userInfo:{
                userName:result.name,
                id:result.id
              }
            
            }
            
            this.props.updateLoginData(loginData)
          } else {
            this.props.history.push('/login?redirect='+window.location.pathname+window.location.search)
          }
        })
        .catch(err=>{
          // this.setState({
          //   showLoginBox:true
          // })
          // this.props.history.push('/login?redirect='+window.location.pathname+window.location.search)
        })
  }

  //改变state内部值
  changeState=(type1,type2,data)=>{
    let state = {...this.state}
    state[type1][type2] = data
    this.setState({
      ...state
    })
    return false
  }

  //退出登录
  handleLogOut=()=>{

    //把cookie清空
    setCookie("token","",-1);
    this.props.history.push('/login?redirect='+window.location.pathname+ window.location.search)
  
    // this.$http.get(this.apiUrl.signOutUrl,{
    //   userId:this.props.loginData.userInfo.id,
    //   // redirect:'/'
    // }).then(
    //   res=>{
    //     if(res.data.code == 1){
    //       this.props.history.push('/login?redirect='+window.location.pathname+ window.location.search)
    //     }else{
    //       message.error('退出失败')
    //     }
    //   }
    // )
  }


  render(){
    let props = this.props
    let state = this.state
    let {navData} = state
    return(
      <div className={this.className(style.headerBox)}>
        <div className={this.className(style.headerIn,process.env.setEnv === 'dev'?style.devBg:'')} >
          <header className={this.className(style.headContent,style.flex)}
                 
          >
         
              <Nav 
                data={navData} 
                history={props.history}
                page={props.page}
              />
              <LoginBox
                history={props.history}
                page = {props.page}
                useInfo={props.loginData.userInfo}
                isLogin={props.loginData.isLogin}
                showModal={this.showModal}
                handleLogOut={this.handleLogOut}
                loginData = {props.loginData}
              />
          
           
          </header>
        </div>
  
      </div>
    )
  }
}
export default withRouter(Header)