const updateInitData = Symbol();


const initialState = {
    initDataInfo:{
        domainId:'',
        searchKey:'',
        index:1,
        size:10,
    }  
}



const initDataInfoReducer =  (initDataInfo={}, action)=>{
    switch(action.type)
    {
        case updateInitData:
            return {
                ...initDataInfo,
                [action.subType]:action.data
            };
        default:
            return initDataInfo
    }
};


export const dispatch_memberManage_func = {

    updateMemberInitData: (data,type) => {
    return dispatch => {
      dispatch({
        type: updateInitData,
        data: data,
        subType:type
      });
    }
  },
  

}


/**
 * ==================================================================
 * Reducer: Reducer的入口
 * ==================================================================
 */
export default (state = initialState, action) => {
    return {
        initDataInfo : initDataInfoReducer(state.initDataInfo, action),
      
    };
}

