import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import common from './common'
import orderDetailBill from './orderDetailBill'
import memberManage from './memberManage'



export default combineReducers({
  router: routerReducer,
  common,
  orderDetailBill,
  memberManage
})