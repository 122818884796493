const GET_BREAD = Symbol();
const DONATE_CITYS = Symbol();
const UPDATE_MODAL_DATA = Symbol();
const UPDATE_LOGIN_DATA = Symbol();

const initialState = {
  modalData: {
    showType: 1,
    visible: false
  },
  loginData: {
    isLogin: true,
    userInfo:{
      userName:'admi11n',
      displayName:'adm1in',
      domainId:'',
      permsList:[],
      groupList:[],
      superMaster:false,
      domainIds:'',
      token:''
    }    
  },
  BreadData: [], //面包屑数据
}


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BREAD:
      return {
        ...state,
        BreadData: action.data
      }
    case UPDATE_MODAL_DATA:
      return {
        ...state,
        modalData: action.data
      }
      case UPDATE_LOGIN_DATA:
      return {
        ...state,
        loginData: action.data
      }
    default:
      return state
  }
}

export const dispatch_common_func = {
  getBread: (data) => {
    return dispatch => {
      dispatch({
        type: GET_BREAD,
        data: data
      });
    }
  },
  updateModalData: (data) => {
    return dispatch => {
      dispatch({
        type: UPDATE_MODAL_DATA,
        data: data
      });
    }
  },
  updateLoginData: (data) => {
    return dispatch => {
      dispatch({
        type: UPDATE_LOGIN_DATA,
        data: data
      });
    }
  },

}

