import React from "react"
import Component, { mapFn, mapStateProps } from '~L/component'
import { dispatch_common_func } from '~R/common'
import { connect } from 'react-redux'
import Header from '../../component/header'
import Bread from '../../component/Bread'
import routesConfig from '../../routes/routes.config.js'
import '~T/common.scss'
import '~T/reset.css'
import requests from '~T/apiRequest'
import {withRouter} from 'react-router-dom'
import { message } from 'antd';
import style from "./index.module.scss"
import classnames from 'classnames';

let Loading = requests.loading
let state = mapStateProps('common')
let fn = mapFn(dispatch_common_func)
@connect(state, fn)
class Layout extends Component {

  componentDidMount() {
    let location = window.location.pathname

    let arr = ['/Register','/login']

  
    // if (arr.includes(location)) return
    // this.checkIsLogin()
    message.config({
      top: 100,
      duration: 2,
      maxCount: 3,
    });

  }

  //判断是否渲染主体的方法
  isShow() {
    let location = window.location.pathname
  
    if(location =="/ats" || location == "/ats/"){
      return false;
    }
    return true;
    // let flag = location == '/originality-control-atp-adr-tyapp' || location == '/login' || location == '/'
    // return flag
  }

  render() {
    let {props} = this
    
    return (

      <div>
        
         {this.isShow()?'':<Header page={props.page}/>} 
          { /* 拿到存到redux的面包屑数据 */ }      
          
          {/* {this.props.BreadData.length>0 && !this.isShow()?<Bread BreadData={props.BreadData} />:''}  */}
          {/* <Skeleton active />  */}
              <div className={classnames(style.bg)}>
              {this.props.children}
              </div>
             
          
          
          <Loading/>   
      </div>
    );
  }
}

export default withRouter(Layout)