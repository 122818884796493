import React from "react"
import Component, { Link } from '~L/component'
import { Breadcrumb,Button  } from 'antd'
import style from './index.module.scss'

/* 面包屑 */
export default class Bread extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    let props = this.props
 
    return (
      <div className={style.breadMain}>
        {/* 当前位置： */}
        <div className={style.Bread}>
          <Breadcrumb separator="/">
            {
              props.BreadData.map((item, index) => {
                return <Breadcrumb.Item key={index}>
                  {
                    item.path === '#' ? item.name : <Link to={item.path}>{item.name}</Link>
                  }
                </Breadcrumb.Item>
              })
            }
          </Breadcrumb>
          {/* <div className={this.className(style.tip,style.flex)}>
                <span className={style.current}>应用列表</span>
                <Button>新建应用</Button>
          </div> */}
        </div>
      </div>
    )
  }
}