import LoadableFunc from "../lib/loadable"
const Arr = [
  { url: "tyapp/index", path: "/", title: "Controllo originalità certificati | ATS San Marino" },

  { url: "atslist/index",path:'/ats', title:'Controllo originalità certificati | ATS San Marino'},
  { url: "login/index" , path:'/login',title:"登录"},
  { url: "tyapp/index", path:"/originality-control-atp-adr-tyapp",title :'Controllo originalit&agrave; certificati | ATS San Marino'}
  
];

Arr.forEach(item => {
  item.component = LoadableFunc(() => import(`../pages/${item.url}`))
})
//路由表
export default Arr